import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import {
  CorpModalScreens,
  IPerson,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalAlertProperties,
  ModalButtonType,
  ModalCategoryType,
} from "redmond";
import { TripsTravelerInfoForm, CorporateButton } from "halifax";
import { ArrowBack } from "@material-ui/icons";
import clsx from "clsx";
import { StepProps } from "../types";
import Header from "../Header";

import baseClasses from "../../styles.module.css";
import { trackEvent } from "../../../../../api/v1/trackEvent";
import { config } from "../../../../../api/config";

interface IProps extends Omit<StepProps, "primaryAction"> {
  primaryAction: (traveler: IPerson) => Promise<void>;
  traveler: IPerson;
  isOnboardingRevampEnabled: boolean;
  isMobile?: boolean;
}

const useStyles = makeStyles(() => ({
  leftButtonWrapper: {
    "& .MuiButton-label": {
      justifyContent: "normal",
    },
  },
  mobileButton: {
    "& .MuiButton-label": {
      justifyContent: "center",
    },
  },
  mobileButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
}));

const AdditionalDetails = ({
  primaryAction,
  secondaryAction,
  onClose,
  traveler,
  isOnboardingRevampEnabled,
  isMobile = false,
}: IProps): React.ReactElement => {
  const [hasSaved, setHasSaved] = useState(false);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const classes = useStyles();

  const modalTitle = "Save additional details";
  const modalSubtitle = isOnboardingRevampEnabled
    ? `Add your travel details now, or skip to add them later when you book.`
    : `Add optional details to your profile to improve future bookings.`;

  const handleSecondaryClick = useCallback(() => {
    secondaryAction(null, 1);
  }, [secondaryAction]);

  const commonTrackingProperties = useMemo(
    () =>
      ({
        type: "confirm_traveler_profile_onboarding",
        screen: CorpModalScreens.ONBOARDING_ADDITIONAL,
        primary_button: "save and continue",
        secondary_button: "back",
        step: CorpModalScreens.ONBOARDING_ADDITIONAL,
        funnel: "other",
        category: ModalCategoryType.FEATURE,
        modal_subtitle: modalSubtitle,
        modal_title: modalTitle,
        agent_title: modalTitle,
        agent_subtitle: modalSubtitle,
      } satisfies ModalAlertProperties),
    []
  );

  useEffect(() => {
    trackEvent({
      eventName: MODAL_ALERT,
      properties: commonTrackingProperties,
    });
  }, []);

  const handleSaveClick = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...commonTrackingProperties,
        button_choice: ModalButtonType.PRIMARY,
      },
    });
    setHasSaved(true);
  };

  const back = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...commonTrackingProperties,
        button_choice: ModalButtonType.SECONDARY,
      },
    });
    handleSecondaryClick();
  };

  const mobileActions = isOnboardingRevampEnabled ? (
    <>
      {!isFormEmpty ? (
        <CorporateButton
          className={classes.mobileButton}
          size="large"
          data-testid="traveler-additional-details-primary-cta"
          onClick={handleSaveClick}
          disabled={isSaveDisabled}
          fullWidth
          style={{ marginBottom: "16px" }}
        >
          Save and continue
        </CorporateButton>
      ) : (
        <CorporateButton
          className={classes.mobileButton}
          size="large"
          onClick={onClose}
          variant="outlined"
          fullWidth
          style={{ marginBottom: "16px" }}
        >
          Skip
        </CorporateButton>
      )}
      <CorporateButton
        className={classes.mobileButton}
        onClick={back}
        size="large"
        variant="text"
        icon={<ArrowBack fontSize="small" />}
        data-testid="traveler-additional-details-secondary-cta"
        fullWidth
      >
        Back
      </CorporateButton>
    </>
  ) : (
    <>
      <CorporateButton
        className={classes.mobileButton}
        size="large"
        data-testid="traveler-additional-details-primary-cta"
        onClick={handleSaveClick}
        disabled={isSaveDisabled || isFormEmpty}
        fullWidth
        style={{ marginBottom: "16px" }}
      >
        Save and continue
      </CorporateButton>
      <CorporateButton
        className={classes.mobileButton}
        onClick={back}
        size="large"
        variant="text"
        icon={<ArrowBack fontSize="small" />}
        data-testid="traveler-additional-details-secondary-cta"
        fullWidth
      >
        Back
      </CorporateButton>
    </>
  );

  const desktopActions = (
    <>
      <CorporateButton
        className={classes.leftButtonWrapper}
        onClick={back}
        size="medium"
        variant="text"
        icon={<ArrowBack fontSize="small" style={{ marginTop: "8px" }} />}
        data-testid="traveler-additional-details-secondary-cta"
      >
        Back
      </CorporateButton>
      <Box style={{ display: "flex" }}>
        {isFormEmpty && (
          <Box style={{ marginRight: "16px" }}>
            <CorporateButton size="medium" onClick={onClose} variant="outlined">
              Skip
            </CorporateButton>
          </Box>
        )}
        <CorporateButton
          size="medium"
          data-testid="traveler-additional-details-primary-cta"
          onClick={handleSaveClick}
          disabled={isSaveDisabled || isFormEmpty}
        >
          Save and continue
        </CorporateButton>
      </Box>
    </>
  );

  return (
    <Box className={baseClasses.stepWrap}>
      <Header
        title={modalTitle}
        subtitle={
          isOnboardingRevampEnabled
            ? `Add your travel details now, or skip to add them later when you book.`
            : `Add optional details to your profile to improve future bookings.`
        }
        currentStep={1}
        onClose={onClose}
        isOnboardingRevampEnabled={isOnboardingRevampEnabled}
        isMobile={isMobile}
      />
      <DialogContent>
        <TripsTravelerInfoForm
          traveler={traveler}
          handleUpdatePassenger={primaryAction}
          buttonText="Save and continue"
          showTravelerInfoSection={false}
          showAdditionalInfoSection
          showFrequentFlyerSection
          showHotelLoyaltySection
          additionalInfoTitle="Additional flight information"
          frequentFlyerTitle="Frequent flyer details"
          hotelLoyaltyTitle="Hotel loyalty details"
          showSecondaryButton
          secondaryButtonText="Back"
          onSecondaryButtonClick={handleSecondaryClick}
          triggerSave={hasSaved}
          setPrimaryButtonDisabled={setIsSaveDisabled}
          hideButtons
          showPassportSection
          className={baseClasses.onboardingModalForm}
          isOnboardingRevampEnabled={isOnboardingRevampEnabled}
          tenant={config.TENANT}
          onAdditionalDetailsDataEmpty={setIsFormEmpty}
          isMobile={isMobile}
        />
      </DialogContent>
      <DialogActions
        className={clsx({ "mobile-dialog-actions": isMobile })}
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent:
            isOnboardingRevampEnabled && !isMobile
              ? "space-between"
              : "flex-end",
          width: "auto",
          alignItems: "center",
        }}
      >
        {isMobile ? mobileActions : desktopActions}
      </DialogActions>
    </Box>
  );
};

export default AdditionalDetails;
