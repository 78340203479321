import {
  Box,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import clsx from "clsx";
import {
  CloseButton,
  CorporateButton,
  CorporateTypography,
  useDeviceTypes,
} from "halifax";
import React, { useEffect, useMemo } from "react";
import {
  CorpModalScreens,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalButtonType,
  ModalCategoryType,
} from "redmond";
import { trackEvent } from "../../../../../api/v1/trackEvent";
import baseClasses from "../../styles.module.css";
import { StepProps } from "../types";
import "./styles.scss";

const useStyles = makeStyles(() => ({
  leftButtonWrapper: {
    "& .MuiButton-label": {
      justifyContent: "normal",
    },
  },
}));

export const YouAreAllSet = ({
  primaryAction,
  secondaryAction,
  onClose,
}: StepProps): React.ReactElement => {
  const classes = useStyles();
  const { matchesMobile } = useDeviceTypes();

  const commonTrackingProperties = useMemo(
    () => ({
      type: "you're_all_set_onboarding",
      screen: CorpModalScreens.YOU_ARE_ALL_SET,
      primary_button: "Start booking",
      secondary_button: "Back",
      step: CorpModalScreens.YOU_ARE_ALL_SET,
      funnel: "other",
      category: ModalCategoryType.FEATURE,
    }),
    []
  );

  useEffect(() => {
    trackEvent({
      eventName: MODAL_ALERT,
      properties: commonTrackingProperties,
    });
  }, []);

  const back = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...commonTrackingProperties,
        button_choice: ModalButtonType.SECONDARY,
      },
    });
    secondaryAction();
  };

  const startBookingClicked = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...commonTrackingProperties,
        button_choice: ModalButtonType.PRIMARY,
      },
    });
    primaryAction();
  };

  return (
    <Box className={baseClasses.stepWrap}>
      <CloseButton onClose={onClose} />
      <DialogContent
        className={clsx("you-are-all-set-dialog-content", {
          mobile: matchesMobile,
        })}
      >
        <CorporateTypography
          variant={matchesMobile ? "h4" : "h1"}
          fontWeight="light"
        >
          You&apos;re all set
        </CorporateTypography>
        <CorporateTypography
          variant={matchesMobile ? "body1" : "subtitle1"}
          fontWeight="light"
          className="subtitle-text"
        >
          We&apos;ll remember your info so you can save time booking.
        </CorporateTypography>
        <Box className="you-are-all-set-illustration" />
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: matchesMobile ? "center" : "space-between",
          width: matchesMobile ? "100%" : "auto",
        }}
      >
        {!matchesMobile && (
          <CorporateButton
            onClick={back}
            size="medium"
            variant="text"
            className={classes.leftButtonWrapper}
            icon={<ArrowBack fontSize="small" style={{ marginTop: "8px" }} />}
            data-testid="you-are-all-set-secondary-cta"
          >
            Back
          </CorporateButton>
        )}
        <CorporateButton
          data-testid="you-are-all-set-primary-cta"
          onClick={startBookingClicked}
          size="large"
          fullWidth={matchesMobile}
        >
          Start Booking
        </CorporateButton>
      </DialogActions>
    </Box>
  );
};
