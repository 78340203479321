import {
  CallState,
  CreditCard,
  Invalid,
  IPerson,
  PassengerError,
  Payment,
  PaymentVerifyResultEnum,
  PersonId,
  FiatPrice,
  RewardsPrice,
  PriceFreezeFlightPaymentFinalizeResult,
  SELECT_TRAVELERS,
  SELECT_REWARDS,
  SELECT_PAYMENT,
  SeatMapCallback,
  GordianSeat,
  FlightBookType,
  PriceDropProtection,
  PRICE_FREEZE_ID_QUERY_PARAM,
  TravelWalletOffer,
  TravelWalletCredit,
  PurchaseError,
  FlightsSubmitForApprovalPayload,
  ICorpPerson,
  EarnResponse,
} from "redmond";

import {
  AccountPrice,
  ErrorCode,
  PriceQuoteData,
  Prices,
  PurchaseResult,
  TripPricingSummary,
  FlightError,
} from "@b2bportal/air-booking-api";

import * as H from "history";
import {
  AncillaryOpaqueValue,
  CipherText,
  PaymentOpaqueValue,
  Product,
} from "@b2bportal/purchase-api";
import * as actionTypes from "./constants";
import { IUpdateUserPassengerRequest } from "../../../api/v0/book/user-passengers/updateUserPassenger";
import { IDeleteUserPassengerRequest } from "../../../api/v0/book/user-passengers/deleteUserPassenger";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";

export const fetchUserPassengers = (): IFetchUserPassengers => ({
  type: actionTypes.FETCH_USER_PASSENGERS,
});

export interface IFetchUserPassengers {
  type: actionTypes.FETCH_USER_PASSENGERS;
}

export const updateUserPassenger = (
  payload: IUpdateUserPassengerArgs,
  autoSelect?: boolean,
  updatePassport?: boolean,
  keepBookingErrors?: boolean,
  isNewTraveler?: boolean,
  defaultTravelerId?: string,
  entryPoint?: string
): IUpdateUserPassenger => ({
  type: actionTypes.UPDATE_USER_PASSENGER,
  payload,
  autoSelect,
  updatePassport,
  keepBookingErrors,
  isNewTraveler,
  defaultTravelerId,
  entryPoint,
});

export interface IUpdateUserPassenger {
  type: actionTypes.UPDATE_USER_PASSENGER;
  payload: IUpdateUserPassengerArgs;
  autoSelect?: boolean;
  updatePassport?: boolean;
  keepBookingErrors?: boolean;
  isNewTraveler?: boolean;
  defaultTravelerId?: string;
  entryPoint?: string;
}

export interface IUpdateUserPassengerArgs extends IUpdateUserPassengerRequest {}

// updates multiple passengers at once
export const updateUserPassengersMultiple = (
  payload: IPerson[]
): IUpdateUserPassengersMultiple => ({
  type: actionTypes.UPDATE_USER_PASSENGERS_MULTIPLE,
  payload,
});

export interface IUpdateUserPassengersMultiple {
  type: actionTypes.UPDATE_USER_PASSENGERS_MULTIPLE;
  payload: IPerson[];
}

export const deleteUserPassenger = (
  payload: IDeleteUserPassengerArgs
): IDeleteUserPassenger => ({
  type: actionTypes.DELETE_USER_PASSENGER,
  payload,
});

export interface IDeleteUserPassenger {
  type: actionTypes.DELETE_USER_PASSENGER;
  payload: IDeleteUserPassengerArgs;
}

export interface IDeleteUserPassengerArgs extends IDeleteUserPassengerRequest {}

export const setInfantOnlyError = (
  infantOnlyError: boolean
): ISetInfantOnlyError => ({
  type: actionTypes.SET_INFANT_ONLY_ERROR,
  infantOnlyError,
});

export interface ISetInfantOnlyError {
  type: actionTypes.SET_INFANT_ONLY_ERROR;
  infantOnlyError: boolean;
}

export const setUserPassengers = (args: {
  userPassengerCallState: CallState;
  userPassengers: IPerson[] | ICorpPerson[];
}): ISetUserPassengers => ({
  type: actionTypes.SET_USER_PASSENGERS,
  ...args,
});

export interface ISetUserPassengers {
  type: actionTypes.SET_USER_PASSENGERS;
  userPassengerCallState: CallState;
  userPassengers: IPerson[] | ICorpPerson[];
}

export const setUserSelectedPassengerIds = (args: {
  userSelectedPassengerIds: PersonId[];
  multiTicketType?: string;
}): ISetUserSelectedPassengerIds => {
  trackEvent({
    eventName: SELECT_TRAVELERS,
    properties: {
      num_travelers: args.userSelectedPassengerIds.length,
      multy_ticket_type: args?.multiTicketType,
    },
  });
  return {
    type: actionTypes.SET_USER_SELECTED_PASSENGER_IDS,
    ...args,
  };
};

export interface ISetUserSelectedPassengerIds {
  type: actionTypes.SET_USER_SELECTED_PASSENGER_IDS;
  userSelectedPassengerIds: PersonId[];
}

export interface ISetUserSelectedLapInfantIds {
  type: actionTypes.SET_USER_SELECTED_LAP_INFANT_IDS;
  userSelectedLapInfantIds: PersonId[];
}

export const setUserSelectedLapInfantIds = (args: {
  userSelectedLapInfantIds: PersonId[];
}): ISetUserSelectedLapInfantIds => ({
  type: actionTypes.SET_USER_SELECTED_LAP_INFANT_IDS,
  ...args,
});

export const setUserSelectedTravelersList = (
  userSelectedTravelersList: IPerson[]
): ISetUserSelectedTravelersList => ({
  type: actionTypes.SET_USER_SELECTED_TRAVELERS_LIST,
  userSelectedTravelersList,
});

export interface ISetUserSelectedTravelersList {
  type: actionTypes.SET_USER_SELECTED_TRAVELERS_LIST;
  userSelectedTravelersList: IPerson[];
}

export const setUserPassengersCallStateFailed =
  (): ISetUserPassengersCallStateFailed => ({
    type: actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED,
  });

export interface ISetUserPassengersCallStateFailed {
  type: actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED;
}

export const acknowledgeUserPassengerFailure =
  (): IAcknowledgeUpdateUserPassengerFailure => ({
    type: actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE,
  });

export interface IAcknowledgeUpdateUserPassengerFailure {
  type: actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE;
}

export interface ISetContactInfo {
  type: actionTypes.SET_CONTACT_INFO;
  email: string;
  phoneNumber: string;
}

export const setContactInfo = (
  email: string,
  phoneNumber: string
): ISetContactInfo => ({
  type: actionTypes.SET_CONTACT_INFO,
  email,
  phoneNumber,
});

export interface ISetUserTcpaConsentPhoneNumber {
  type: actionTypes.SET_USER_TCPA_CONSENT_PHONE_NUMBER;
  tcpaConsentPhoneNumber: string;
}

export const setUserTcpaConsentPhoneNumber = (
  tcpaConsentPhoneNumber: string
): ISetUserTcpaConsentPhoneNumber => ({
  type: actionTypes.SET_USER_TCPA_CONSENT_PHONE_NUMBER,
  tcpaConsentPhoneNumber,
});

export interface IToggleSMSOptIn {
  type: actionTypes.TOGGLE_SMS_OPT_IN;
}

export const toggleSMSOptIn = (): IToggleSMSOptIn => ({
  type: actionTypes.TOGGLE_SMS_OPT_IN,
});

export interface IToggleAppNotifOptIn {
  type: actionTypes.TOGGLE_APP_NOTIF_OPT_IN;
}

export const toggleAppNotifOptIn = (): IToggleAppNotifOptIn => ({
  type: actionTypes.TOGGLE_APP_NOTIF_OPT_IN,
});

export const fetchPaymentMethod = (paymentId: string): IFetchPaymentMethod => ({
  type: actionTypes.FETCH_PAYMENT_METHOD,
  paymentId,
});

export interface IFetchPaymentMethod {
  type: actionTypes.FETCH_PAYMENT_METHOD;
  paymentId: string;
}

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const verifyPaymentMethod = (
  payload: IVerifyPaymentMethodArgs,
  accountId: string,
  isVCN: boolean
): IVerifyPaymentMethod => ({
  type: actionTypes.VERIFY_PAYMENT_METHOD,
  payload,
  accountId,
  isVCN,
});

export interface IVerifyPaymentMethod {
  type: actionTypes.VERIFY_PAYMENT_METHOD;
  payload: IVerifyPaymentMethodArgs;
  accountId: string;
  isVCN: boolean;
}

export interface IVerifyPaymentMethodArgs {
  token: string;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethod = (args: {
  paymentMethod?: CreditCard;
}): ISetPaymentMethod => ({
  type: actionTypes.SET_PAYMENT_METHOD,
  ...args,
});

export interface ISetPaymentMethod {
  type: actionTypes.SET_PAYMENT_METHOD;
  paymentMethod?: CreditCard;
}

export const resetPaymentCardSelectedAccounts =
  (): IResetPaymentCardSelectedAccounts => ({
    type: actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS,
  });

export interface IResetPaymentCardSelectedAccounts {
  type: actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS;
}

export const setSelectedPaymentMethodId = (args: {
  paymentMethodId?: string;
  accountId?: string;
  multiTicketType?: string;
}): ISetSelectedPaymentMethodId => {
  trackEvent({
    eventName: SELECT_PAYMENT,
    properties: {
      multy_ticket_type: args?.multiTicketType,
    },
  });
  return {
    type: actionTypes.SET_SELECTED_PAYMENT_METHOD_ID,
    ...args,
  };
};

export interface ISetSelectedPaymentMethodId {
  type: actionTypes.SET_SELECTED_PAYMENT_METHOD_ID;
  paymentMethodId?: string;
  accountId?: string;
}

export const deletePaymentMethod = (args: {
  paymentId: string;
}): IDeletePaymentMethod => ({
  type: actionTypes.DELETE_PAYMENT_METHOD,
  ...args,
});

export interface IDeletePaymentMethod {
  type: actionTypes.DELETE_PAYMENT_METHOD;
  paymentId: string;
}

export const deletePaymentMethodCallStateFailed =
  (): IDeletePaymentMethodCallStateFailed => ({
    type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED,
  });

export interface IDeletePaymentMethodCallStateFailed {
  type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED;
}

export const deletePaymentMethodCallStateSuccess =
  (): IDeletePaymentMethodCallStateSuccess => ({
    type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS,
  });

export interface IDeletePaymentMethodCallStateSuccess {
  type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS;
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

export const setPaymentMethodCallStateFailed =
  (): ISetPaymentMethodCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED;
}

export const verifyPaymentMethodCallStateFailed = (
  result: PaymentVerifyResultEnum
): IVerifyPaymentMethodCallStateFailed => ({
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED,
  result,
});

export interface IVerifyPaymentMethodCallStateFailed {
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED;
  result: PaymentVerifyResultEnum;
}

export const verifyPaymentMethodCallStateSuccess =
  (): IVerifyPaymentMethodCallStateSuccess => ({
    type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS,
  });

export interface IVerifyPaymentMethodCallStateSuccess {
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS;
}

export const setSession = (payload: CipherText): ISetSession => ({
  type: actionTypes.SET_SESSION,
  payload,
});

export interface ISetSession {
  type: actionTypes.SET_SESSION;
  payload: CipherText;
}

export const closeSession = (args: {
  resetSessionOnly?: boolean;
}): ICloseSession => ({
  type: actionTypes.CLOSE_SESSION,
  ...args,
});

export interface ICloseSession {
  type: actionTypes.CLOSE_SESSION;
  resetSessionOnly?: boolean;
}

export interface ICloseSessionCallStateSuccess {
  type: actionTypes.CLOSE_SESSION_CALL_STATE_SUCCESS;
}
export const closeSessionCallStateSuccess =
  (): ICloseSessionCallStateSuccess => ({
    type: actionTypes.CLOSE_SESSION_CALL_STATE_SUCCESS,
  });

export interface ICloseSessionCallStateFailed {
  type: actionTypes.CLOSE_SESSION_CALL_STATE_FAILED;
}

export const closeSessionCallStateFailed =
  (): ICloseSessionCallStateFailed => ({
    type: actionTypes.CLOSE_SESSION_CALL_STATE_FAILED,
  });

export const scheduleQuote = (args: {
  agentFee?: number;
  pollQuoteOnly?: boolean;
  isPriceFreezePurchase?: boolean;
  // note: the current price quote will be set to null on default, and this param is used to control that behaviour
  preserveQuote?: boolean;
}): IScheduleQuote => ({
  type: actionTypes.SCHEDULE_QUOTE,
  ...args,
});

export interface IScheduleQuote {
  type: actionTypes.SCHEDULE_QUOTE;
  agentFee?: number;
  pollQuoteOnly?: boolean;
  isPriceFreezePurchase?: boolean;
  preserveQuote?: boolean;
}

export const setScheduleQuoteCallStateSuccess =
  (): ISetScheduleQuoteCallStateSuccess => ({
    type: actionTypes.SCHEDULE_QUOTE_CALL_STATE_SUCCESS,
  });

export interface ISetScheduleQuoteCallStateSuccess {
  type: actionTypes.SCHEDULE_QUOTE_CALL_STATE_SUCCESS;
}

export const setScheduleQuoteCallStateFailed = (
  invalid: Invalid
): ISetScheduleQuoteCallStateFailure => ({
  type: actionTypes.SCHEDULE_QUOTE_CALL_STATE_FAILED,
  invalid,
});

export interface ISetScheduleQuoteCallStateFailure {
  type: actionTypes.SCHEDULE_QUOTE_CALL_STATE_FAILED;
  invalid: Invalid;
}

export const cancelScheduledQuote = () => ({
  type: actionTypes.SCHEDULED_QUOTE_CANCELLED,
});

export interface ICancelScheduledQuote {
  type: actionTypes.SCHEDULED_QUOTE_CANCELLED;
}

export const pollQuote = (
  agentFee?: number,
  pollQuoteOnly?: boolean,
  isPriceFreezePurchase?: boolean
): IPollQuote => ({
  type: actionTypes.POLL_QUOTE,
  agentFee,
  pollQuoteOnly,
  isPriceFreezePurchase,
});

export interface IPollQuote {
  type: actionTypes.POLL_QUOTE;
  agentFee?: number;
  pollQuoteOnly?: boolean;
  isPriceFreezePurchase?: boolean;
}

export const setPollQuoteCallStateSuccess = (): IPollQuoteCallStateSuccess => ({
  type: actionTypes.SET_POLL_QUOTE_CALL_STATE_SUCCESS,
});

export interface IPollQuoteCallStateSuccess {
  type: actionTypes.SET_POLL_QUOTE_CALL_STATE_SUCCESS;
}

export const setPollQuoteCallStateFailure = (
  errors: (PurchaseError | FlightError)[]
): ISetPollQuoteCallStateFailure => ({
  type: actionTypes.SET_POLL_QUOTE_CALL_STATE_FAILED,
  errors,
});

export interface ISetPollQuoteCallStateFailure {
  type: actionTypes.SET_POLL_QUOTE_CALL_STATE_FAILED;
  errors: (PurchaseError | FlightError)[];
}

export const setQuote = (
  priceQuote: PriceQuoteData | null,
  preservePayment?: boolean
): ISetQuote => ({
  type: actionTypes.SET_QUOTE,
  priceQuote,
  preservePayment,
});

export interface ISetQuote {
  type: actionTypes.SET_QUOTE;
  priceQuote: PriceQuoteData | null;
  preservePayment?: boolean;
}

// this is the total rewards cost of the trip we get from the BE after price quote completes
// this should only be set when the selected account is tiered
// this is beacause seats rewards pricing is only calculated on the FE and the total cost (seats + flight)
// for tiered accounts will differ based on the tiered calculation logic
export const setQuotedRewardsTotal = (
  quotedRewardsTotal: RewardsPrice | null
): ISetQuotedRewardsTotal => ({
  type: actionTypes.SET_QUOTE_REWARDS_TOTAL,
  quotedRewardsTotal,
});

export interface ISetQuotedRewardsTotal {
  type: actionTypes.SET_QUOTE_REWARDS_TOTAL;
  quotedRewardsTotal: RewardsPrice | null;
}

export const setPriceQuoteSuccessTime = (
  priceQuoteSuccessTime: string | null
): ISetPriceQuoteSuccessTime => ({
  type: actionTypes.SET_PRICE_QUOTE_SUCCESS_TIME,
  priceQuoteSuccessTime,
});

export interface ISetPriceQuoteSuccessTime {
  type: actionTypes.SET_PRICE_QUOTE_SUCCESS_TIME;
  priceQuoteSuccessTime: string | null;
}

export const setPriceQuote = (priceQuote: PriceQuoteData): ISetPriceQuote => ({
  type: actionTypes.SET_PRICE_QUOTE,
  priceQuote,
});
export interface ISetPriceQuote {
  type: actionTypes.SET_PRICE_QUOTE;
  priceQuote: PriceQuoteData;
}

export const validatePassengers = (
  seatedPassengerIds: string[],
  lapInfantsIds: string[]
): IValidatePassengers => ({
  type: actionTypes.VALIDATE_PASSENGERS,
  seatedPassengerIds,
  lapInfantsIds,
});

export interface IValidatePassengers {
  type: actionTypes.VALIDATE_PASSENGERS;
  seatedPassengerIds: string[];
  lapInfantsIds: string[];
}

export const setPassengerValidationCallStateSuccess =
  (): IPassengerValidationCallStateSuccess => ({
    type: actionTypes.PASSENGER_VALIDATION_CALL_STATE_SUCCESS,
  });

export interface IPassengerValidationCallStateSuccess {
  type: actionTypes.PASSENGER_VALIDATION_CALL_STATE_SUCCESS;
}

export const setPassengerValidationCallStateFailure =
  (): IPassengerValidationCallStateFailure => ({
    type: actionTypes.PASSENGER_VALIDATION_CALL_STATE_FAILED,
  });

export interface IPassengerValidationCallStateFailure {
  type: actionTypes.PASSENGER_VALIDATION_CALL_STATE_FAILED;
}

export const fetchTripPricing = (): IFetchTripPricing => ({
  type: actionTypes.FETCH_TRIP_PRICING,
});

export interface IFetchTripPricing {
  type: actionTypes.FETCH_TRIP_PRICING;
}

export const setFetchTripPricingCallStateSuccess =
  (): IFetchTripPricingCallStateSuccess => ({
    type: actionTypes.FETCH_TRIP_PRICING_CALL_STATE_SUCCESS,
  });

export interface IFetchTripPricingCallStateSuccess {
  type: actionTypes.FETCH_TRIP_PRICING_CALL_STATE_SUCCESS;
}

export const setFetchTripPricingCallStateFailure = (
  errors: ErrorCode[]
): IFetchTripPricingCallStateFailure => ({
  type: actionTypes.FETCH_TRIP_PRICING_CALL_STATE_FAILURE,
  errors,
});

export interface IFetchTripPricingCallStateFailure {
  type: actionTypes.FETCH_TRIP_PRICING_CALL_STATE_FAILURE;
  errors: ErrorCode[];
}

export const setTripPricing = (
  tripPricing: TripPricingSummary
): ISetTripPricing => ({
  type: actionTypes.SET_TRIP_PRICING,
  tripPricing,
});

export interface ISetTripPricing {
  type: actionTypes.SET_TRIP_PRICING;
  tripPricing: TripPricingSummary;
}

export const setPassengersValid = (): ISetPassengersValid => ({
  type: actionTypes.SET_PASSENGERS_VALID,
});

export interface ISetPassengersValid {
  type: actionTypes.SET_PASSENGERS_VALID;
}

export const setPassengersInvalid = (
  errors: PassengerError[]
): ISetPassengersInvalid => ({
  type: actionTypes.SET_PASSENGERS_INVALID,
  errors,
});

export interface ISetPassengersInvalid {
  type: actionTypes.SET_PASSENGERS_INVALID;
  errors: PassengerError[];
}

export const schedulePayment = (args: {
  session?: CipherText;
  payments?: PaymentOpaqueValue[];
  ancillaries: AncillaryOpaqueValue[];
  agentFee?: number;
  isPriceFreezePurchase?: boolean;
  approvalRequestReason?: string;
}): ISetSchedulePayment => ({
  type: actionTypes.SCHEDULE_PAYMENT,
  ...args,
});

export interface ISetSchedulePayment {
  type: actionTypes.SCHEDULE_PAYMENT;
  session?: CipherText;
  payments?: PaymentOpaqueValue[] | null;
  ancillaries: AncillaryOpaqueValue[];
  agentFee?: number;
  isPriceFreezePurchase?: boolean;
  approvalRequestReason?: string;
}

export const setSchedulePaymentSuccess = (): ISetSchedulePaymentSuccess => ({
  type: actionTypes.SET_SCHEDULE_PAYMENT_SUCCESS,
});

export interface ISetSchedulePaymentSuccess {
  type: actionTypes.SET_SCHEDULE_PAYMENT_SUCCESS;
}

export const setSchedulePaymentFailed = (
  invalid: Invalid
): ISetSchedulePaymentFailed => ({
  type: actionTypes.SET_SCHEDULE_PAYMENT_FAILED,
  invalid,
});

export interface ISetSchedulePaymentFailed {
  type: actionTypes.SET_SCHEDULE_PAYMENT_FAILED;
  invalid: Invalid;
}

export const setSchedulePaymentNotCalled =
  (): ISetSchedulePaymentNotCalled => ({
    type: actionTypes.SET_SCHEDULE_PAYMENT_NOT_CALLED,
  });

export interface ISetSchedulePaymentNotCalled {
  type: actionTypes.SET_SCHEDULE_PAYMENT_NOT_CALLED;
}

export const redoSearch = (): IRedoSearch => ({
  type: actionTypes.REDO_SEARCH,
});

export interface IRedoSearch {
  type: actionTypes.REDO_SEARCH;
}

export const acknowledgePriceDifference = (): IAcknowledgePriceDifference => ({
  type: actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE,
});

export interface IAcknowledgePriceDifference {
  type: actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE;
}

export interface IFlightBookOverwriteQueryParams {
  [PRICE_FREEZE_ID_QUERY_PARAM]?: string;
}

export interface IPopulateFlightBookQueryParams {
  type: actionTypes.POPULATE_FLIGHT_BOOK_QUERY_PARAMS;
  history: H.History;
  pathname?: string;
  preserveQuery?: boolean;
  // note: this prop is used for setting new query params that should be set prior to setUpFlightBookParamsSaga
  newQueryParams?: IFlightBookOverwriteQueryParams;
}

export const populateFlightBookQueryParams = (args: {
  history: H.History;
  pathname?: string;
  preserveQuery?: boolean;
  newQueryParams?: IFlightBookOverwriteQueryParams;
}): IPopulateFlightBookQueryParams => ({
  type: actionTypes.POPULATE_FLIGHT_BOOK_QUERY_PARAMS,
  ...args,
});

export interface ISetFlightBookQueryParams {
  type: actionTypes.SET_FLIGHT_BOOK_QUERY_PARAMS;
  history: H.History;
}

export const setFlightBookQueryParams = (
  history: H.History
): ISetFlightBookQueryParams => ({
  type: actionTypes.SET_FLIGHT_BOOK_QUERY_PARAMS,
  history,
});

export interface ISetSelectedRewardsAccountReferenceId {
  type: actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID;
  rewardsAccountReferenceId: string | null;
}

export const setSelectedRewardsAccountReferenceId = (
  rewardsAccountReferenceId: string | null
): ISetSelectedRewardsAccountReferenceId => {
  trackEvent({
    eventName: SELECT_REWARDS,
    properties: {},
  });
  return {
    type: actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID,
    rewardsAccountReferenceId,
  };
};

export interface ISetSelectedRewardsPaymentTotal {
  type: actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL;
  accountReferenceId: string;
  rewardsPaymentTotal: RewardsPrice | null;
  rewardsPaymentInFiatCurrency: FiatPrice | null;
  total?: Prices;
}

export const setSelectedRewardsPaymentTotal = (
  accountReferenceId: string,
  rewardsPaymentTotal: AccountPrice | null,
  rewardsPaymentInFiatCurrency: FiatPrice | null,
  total?: Prices
): ISetSelectedRewardsPaymentTotal => ({
  type: actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL,
  accountReferenceId,
  rewardsPaymentTotal,
  rewardsPaymentInFiatCurrency,
  total,
});

export const setRewardsConversionFailed = (): IRewardsConversionFailed => ({
  type: actionTypes.REWARDS_CONVERSION_FAILED,
});

export interface IRewardsConversionFailed {
  type: actionTypes.REWARDS_CONVERSION_FAILED;
}

export const pollFinalized = (
  agentFee?: number,
  isPriceFreezePurchase?: boolean
): IPollFinalized => ({
  type: actionTypes.POLL_FINALIZED,
  agentFee,
  isPriceFreezePurchase,
});

export interface IPollFinalized {
  type: actionTypes.POLL_FINALIZED;
  agentFee?: number;
  isPriceFreezePurchase?: boolean;
}

export const setPollFinalizedCallStatePending =
  (): IPollFinalizedCallStatePending => ({
    type: actionTypes.POLL_FINALIZED_CALL_STATE_PENDING,
  });

export interface IPollFinalizedCallStatePending {
  type: actionTypes.POLL_FINALIZED_CALL_STATE_PENDING;
}

export const setPollFinalizedCallStateSuccess =
  (): IPollFinalizedCallStateSuccess => ({
    type: actionTypes.POLL_FINALIZED_CALL_STATE_SUCCESS,
  });

export interface IPollFinalizedCallStateSuccess {
  type: actionTypes.POLL_FINALIZED_CALL_STATE_SUCCESS;
}

export const setPollFinalizedCallStateFailure = (
  errors: (PurchaseError | FlightError)[]
): ISetPollFinalizedCallStateFailure => ({
  type: actionTypes.POLL_FINALIZED_CALL_STATE_FAILED,
  errors,
});

export interface ISetPollFinalizedCallStateFailure {
  type: actionTypes.POLL_FINALIZED_CALL_STATE_FAILED;
  errors: (PurchaseError | FlightError)[];
}

export const setFinalized = (
  product: Product,
  finalizeResult: PurchaseResult | PriceFreezeFlightPaymentFinalizeResult
): ISetFinalized => ({
  type: actionTypes.SET_FINALIZED,
  product,
  finalizeResult,
});

export interface ISetFinalized {
  type: actionTypes.SET_FINALIZED;
  product: Product;
  finalizeResult: PurchaseResult | PriceFreezeFlightPaymentFinalizeResult;
}

export const resetFinalized = (): IResetFinalized => ({
  type: actionTypes.RESET_FINALIZED,
});

export interface IResetFinalized {
  type: actionTypes.RESET_FINALIZED;
}

export interface IFetchProductToEarn {
  type: actionTypes.FETCH_PRODUCT_TO_EARN;
}

export const fetchProductToEarn = (): IFetchProductToEarn => ({
  type: actionTypes.FETCH_PRODUCT_TO_EARN,
});

export interface ISetProductEarnValue {
  type: actionTypes.SET_PRODUCT_EARN_VALUE;
  productEarnValue: number;
}

export const setEarnValue = (
  productEarnValue: number
): ISetProductEarnValue => ({
  type: actionTypes.SET_PRODUCT_EARN_VALUE,
  productEarnValue,
});

export interface IFetchAllEarnForProduct {
  type: actionTypes.FETCH_ALL_EARN_FOR_PRODUCT;
}

export const fetchAllEarnForProduct = (): IFetchAllEarnForProduct => ({
  type: actionTypes.FETCH_ALL_EARN_FOR_PRODUCT,
});

export interface ISetAllEarnValues {
  type: actionTypes.SET_ALL_EARN_VALUES;
  earnValuesByRewardAcctId: { [key: string]: EarnResponse };
}

export const setAllEarnValues = (earnValuesByRewardAcctId: {
  [key: string]: EarnResponse;
}): ISetAllEarnValues => ({
  type: actionTypes.SET_ALL_EARN_VALUES,
  earnValuesByRewardAcctId,
});

export interface ISetupFlightBookParams {
  type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS;
  history: H.History;
  flightBookType?: FlightBookType;
}

export const setUpFlightBookParams = (args: {
  history: H.History;
  flightBookType?: FlightBookType;
}): ISetupFlightBookParams => ({
  type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS,
  ...args,
});

export interface ISetUpFlightBookParamsFailed {
  type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_FAILED;
}

export const setUpFlightBookParamsFailed =
  (): ISetUpFlightBookParamsFailed => ({
    type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_FAILED,
  });

export interface ISetUpFlightBookParamsSuccess {
  type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS;
}

export const setUpFlightBookParamsSuccess =
  (): ISetUpFlightBookParamsSuccess => ({
    type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS,
  });

export interface IResetBookingErrors {
  type: actionTypes.RESET_BOOKING_ERRORS;
  isPostQuote: boolean;
}

export const resetBookingErrors = (
  isPostQuote?: boolean
): IResetBookingErrors => ({
  type: actionTypes.RESET_BOOKING_ERRORS,
  isPostQuote: isPostQuote ?? false,
});

export interface IChargeAgentBookingFee {
  type: actionTypes.CHARGE_AGENT_BOOKING_FEE;
  agentEmail: string;
}

export const chargeAgentBookingFee = (
  agentEmail: string
): IChargeAgentBookingFee => ({
  type: actionTypes.CHARGE_AGENT_BOOKING_FEE,
  agentEmail,
});

export interface IChargeAgentBookingFeeFailed {
  type: actionTypes.CHARGE_AGENT_BOOKING_FEE_FAILED;
}

export const chargeAgentBookingFeeFailed =
  (): IChargeAgentBookingFeeFailed => ({
    type: actionTypes.CHARGE_AGENT_BOOKING_FEE_FAILED,
  });

export interface IChargeAgentBookingFeeSuccess {
  type: actionTypes.CHARGE_AGENT_BOOKING_FEE_SUCCESS;
}

export const chargeAgentBookingFeeSuccess =
  (): IChargeAgentBookingFeeSuccess => ({
    type: actionTypes.CHARGE_AGENT_BOOKING_FEE_SUCCESS,
  });

export interface IFetchSeatMap {
  type: actionTypes.FETCH_SEAT_MAP;
  callback: SeatMapCallback;
}

export const fetchSeatMap = (callback: SeatMapCallback): IFetchSeatMap => ({
  type: actionTypes.FETCH_SEAT_MAP,
  callback,
});

export interface ISetSeatMap {
  type: actionTypes.SET_SEAT_MAP;
  seatMapHtml: string | null;
}

export const setSeatMap = (seatMapHtml: string | null): ISetSeatMap => ({
  type: actionTypes.SET_SEAT_MAP,
  seatMapHtml,
});

export interface ISetCheapestSeat {
  type: actionTypes.SET_CHEAPEST_SEAT;
  cheapestSeat: Prices;
}

export const setCheapestSeat = (cheapestSeat: Prices): ISetCheapestSeat => ({
  type: actionTypes.SET_CHEAPEST_SEAT,
  cheapestSeat,
});

export interface IFetchSeatMapFailed {
  type: actionTypes.FETCH_SEAT_MAP_FAILED;
}
export const fetchSeatMapFailed = () => ({
  type: actionTypes.FETCH_SEAT_MAP_FAILED,
});

export interface ISetSkipSeatSelection {
  type: actionTypes.SET_SKIP_SEAT_SELECTION;
  skipSelection: boolean;
}

export const setSkipSeatSelection = (
  skipSelection: boolean
): ISetSkipSeatSelection => ({
  type: actionTypes.SET_SKIP_SEAT_SELECTION,
  skipSelection,
});
export interface ISetSelectedSeats {
  type: actionTypes.SET_SELECTED_SEATS;
  selectedSeats: GordianSeat[];
}
export const setSelectedSeats = (
  selectedSeats: GordianSeat[]
): ISetSelectedSeats => ({
  type: actionTypes.SET_SELECTED_SEATS,
  selectedSeats,
});

export interface IFetchPriceDropEligibility {
  type: actionTypes.FETCH_PRICE_DROP_ELIGIBILITY;
  candidateId: string;
}
export const fetchPriceDropEligibility = (
  candidateId: string
): IFetchPriceDropEligibility => ({
  type: actionTypes.FETCH_PRICE_DROP_ELIGIBILITY,
  candidateId,
});

export interface ISetPriceDropProtection {
  type: actionTypes.SET_PRICE_DROP_PROTECTION;
  priceDropProtection: PriceDropProtection | null;
  fetchPriceDropEligibilityCallState: CallState;
}
export const setPriceDropProtection = (args: {
  priceDropProtection: PriceDropProtection | null;
  fetchPriceDropEligibilityCallState: CallState;
}): ISetPriceDropProtection => ({
  type: actionTypes.SET_PRICE_DROP_PROTECTION,
  ...args,
});

export interface ISetIsSimilarFlightsEnabled {
  type: actionTypes.SET_IS_SIMILAR_FLIGHTS_ENABLED;
  isSimilarFlightsEnabled: boolean;
}

export const setIsSimilarFlightsEnabled = (
  isSimilarFlightsEnabled: boolean
): ISetIsSimilarFlightsEnabled => ({
  type: actionTypes.SET_IS_SIMILAR_FLIGHTS_ENABLED,
  isSimilarFlightsEnabled,
});

export interface ISetFlightBookType {
  type: actionTypes.SET_FLIGHT_BOOK_TYPE;
  flightBookType: FlightBookType;
}

export const setFlightBookType = (
  flightBookType: FlightBookType
): ISetFlightBookType => ({
  type: actionTypes.SET_FLIGHT_BOOK_TYPE,
  flightBookType,
});

export interface ISetOfferToApply {
  type: actionTypes.SET_OFFER_TO_APPLY;
  offer?: TravelWalletOffer;
}

export const setOfferToApply = (
  offer?: TravelWalletOffer
): ISetOfferToApply => ({
  type: actionTypes.SET_OFFER_TO_APPLY,
  offer,
});

export interface ISetBestOfferOverall {
  type: actionTypes.SET_BEST_OFFER_OVERALL;
  offer: TravelWalletOffer;
}

export const setBestOfferOverall = (
  offer: TravelWalletOffer
): ISetBestOfferOverall => ({
  type: actionTypes.SET_BEST_OFFER_OVERALL,
  offer,
});

export interface ISetTravelWalletOffers {
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS;
  offers: TravelWalletOffer[];
}

export const setTravelWalletOffers = (
  offers: TravelWalletOffer[]
): ISetTravelWalletOffers => ({
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS,
  offers,
});

export interface ISetTravelWalletCredit {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT;
  credit?: TravelWalletCredit;
}

export const setTravelWalletCredit = (
  credit?: TravelWalletCredit
): ISetTravelWalletCredit => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT,
  credit,
});

export interface ISetCreditToApply {
  type: actionTypes.SET_CREDIT_TO_APPLY;
  credit?: TravelWalletCredit;
}

export const setCreditToApply = (
  credit?: TravelWalletCredit
): ISetCreditToApply => ({
  type: actionTypes.SET_CREDIT_TO_APPLY,
  credit,
});

export interface IFetchApplicableTravelWalletItems {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS;
}

export const fetchApplicableTravelWalletItems =
  (): IFetchApplicableTravelWalletItems => ({
    type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS,
  });

export interface IFetchApplicableTravelWalletItemsCallStateSuccess {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS;
}

export const setFetchApplicableTravelWalletItemsCallStateSuccess =
  (): IFetchApplicableTravelWalletItemsCallStateSuccess => ({
    type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS,
  });

export interface IFetchApplicableTravelWalletItemsCallStateFailure {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE;
}

export const setFetchApplicableTravelWalletItemsCallStateFailure =
  (): IFetchApplicableTravelWalletItemsCallStateFailure => ({
    type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE,
  });

// Corporate Travel
export interface FlightsSubmitForApprovalResponse
  extends FlightsSubmitForApprovalPayload {}

export interface ISetSubmitForApproval {
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL;
  payload: FlightsSubmitForApprovalResponse;
}

export const setSubmitForApproval = (
  payload: FlightsSubmitForApprovalResponse
): ISetSubmitForApproval => ({
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL,
  payload,
});

export interface ISetSubmitForApprovalSuccess {
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL_SUCCESS;
}

export const setSubmitForApprovalSuccess =
  (): ISetSubmitForApprovalSuccess => ({
    type: actionTypes.SET_SUBMIT_FOR_APPROVAL_SUCCESS,
  });

export interface ISetSubmitForApprovalFailure {
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL_FAILURE;
  invalid: Invalid;
}

export const setSubmitForApprovalFailure = (
  invalid: Invalid
): ISetSubmitForApprovalFailure => ({
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL_FAILURE,
  invalid,
});

export const fetchCorpUserPassengers = (): IFetchCorpUserPassengers => ({
  type: actionTypes.FETCH_CORP_USER_PASSENGERS,
});

export interface IFetchCorpUserPassengers {
  type: actionTypes.FETCH_CORP_USER_PASSENGERS;
}

export interface ISetTripPurpose {
  type: actionTypes.SET_TRIP_PURPOSE;
  tripPurpose: string;
}

export const setTripPurpose = (tripPurpose: string): ISetTripPurpose => ({
  type: actionTypes.SET_TRIP_PURPOSE,
  tripPurpose,
});

export type FlightBookActions =
  | IFetchUserPassengers
  | IUpdateUserPassenger
  | IDeleteUserPassenger
  | ISetInfantOnlyError
  | ISetUserPassengers
  | ISetUserSelectedPassengerIds
  | ISetUserSelectedLapInfantIds
  | ISetUserPassengersCallStateFailed
  | IAcknowledgeUpdateUserPassengerFailure
  | ISetContactInfo
  | ISetUserTcpaConsentPhoneNumber
  | IToggleSMSOptIn
  | IToggleAppNotifOptIn
  | IFetchPaymentMethod
  | IListPaymentMethods
  | IDeletePaymentMethod
  | ISetPaymentMethod
  | ISetPaymentMethods
  | IResetPaymentCardSelectedAccounts
  | ISetSelectedPaymentMethodId
  | IVerifyPaymentMethod
  | IVerifyPaymentMethodCallStateSuccess
  | IVerifyPaymentMethodCallStateFailed
  | IDeletePaymentMethodCallStateSuccess
  | IDeletePaymentMethodCallStateFailed
  | ISetPaymentMethodsCallStateFailed
  | ISetPaymentMethodCallStateFailed
  | ISetSession
  | ICloseSession
  | ICloseSessionCallStateSuccess
  | ICloseSessionCallStateFailed
  | IScheduleQuote
  | ISetScheduleQuoteCallStateSuccess
  | ISetScheduleQuoteCallStateFailure
  | ICancelScheduledQuote
  | IPollQuote
  | IPollQuoteCallStateSuccess
  | ISetPollQuoteCallStateFailure
  | ISetQuote
  | ISetPriceQuote
  | IValidatePassengers
  | IPassengerValidationCallStateSuccess
  | IPassengerValidationCallStateFailure
  | ISetPassengersValid
  | ISetPassengersInvalid
  | ISetSchedulePayment
  | ISetSchedulePaymentFailed
  | ISetSchedulePaymentNotCalled
  | ISetSchedulePaymentSuccess
  | IRedoSearch
  | IAcknowledgePriceDifference
  | IPopulateFlightBookQueryParams
  | ISetFlightBookQueryParams
  | ISetUpFlightBookParamsFailed
  | ISetUpFlightBookParamsSuccess
  | ISetSelectedRewardsAccountReferenceId
  | IFetchProductToEarn
  | ISetProductEarnValue
  | IFetchAllEarnForProduct
  | ISetAllEarnValues
  | IFetchTripPricingCallStateFailure
  | IFetchTripPricing
  | ISetTripPricing
  | IFetchTripPricing
  | IFetchTripPricingCallStateFailure
  | IFetchTripPricingCallStateSuccess
  | IPollFinalized
  | ISetPollFinalizedCallStateFailure
  | IPollFinalizedCallStateSuccess
  | IPollFinalizedCallStatePending
  | ISetFinalized
  | ISetSelectedRewardsPaymentTotal
  | ISetupFlightBookParams
  | IResetBookingErrors
  | IRewardsConversionFailed
  | IChargeAgentBookingFee
  | IChargeAgentBookingFeeFailed
  | IChargeAgentBookingFeeSuccess
  | IFetchSeatMap
  | ISetSeatMap
  | ISetCheapestSeat
  | IFetchSeatMapFailed
  | ISetSkipSeatSelection
  | ISetSelectedSeats
  | IFetchPriceDropEligibility
  | ISetPriceDropProtection
  | ISetFlightBookType
  | ISetQuotedRewardsTotal
  | ISetPriceQuoteSuccessTime
  | ISetIsSimilarFlightsEnabled
  | IResetFinalized
  | ISetBestOfferOverall
  | ISetOfferToApply
  | ISetTravelWalletOffers
  | IFetchApplicableTravelWalletItems
  | IFetchApplicableTravelWalletItemsCallStateSuccess
  | IFetchApplicableTravelWalletItemsCallStateFailure
  | ISetTravelWalletCredit
  | ISetCreditToApply
  | ISetSubmitForApproval
  | ISetSubmitForApprovalSuccess
  | ISetSubmitForApprovalFailure
  | ISetSubmitForApprovalFailure
  | IFetchCorpUserPassengers
  | ISetTripPurpose
  | ISetUserSelectedTravelersList
  | IUpdateUserPassengersMultiple;
